<template>
    <AppPaginatedGridNested
        :service="service"
        parentName="fonte-geradora"
        :defaultProps="{ fonteGeradoraId: this.$route.params.id }"
        :expander="false"
        labelBotaoAdicionarItem="Perigo/Fator de Risco"
        :isValid="isValid"
        :queryParams="queryParams"
    >
        <template #columns>
            <Column field="risco" header="Perigo/Fator de Risco">
                <template #editor="{ data, field }">
                    <DropdownRiscos v-model="data[field]" @update:modelValue="data.riscoId = data[field]?.id" />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.risco?.nome }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.createdAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import DropdownRiscos from '../../riscos/components/DropdownRiscos.vue';
import NestedService from '../../../services/NestedService';
export default {
    emits: ['desabilitarAbas', 'habilitarAbas'],
    components: {
        DropdownRiscos
    },
    data() {
        return {
            formulario: {},
            isValid: true,
            submitted: false,
            isInativo: false
        };
    },
    mounted() {
        this.isInativo = !!this.$route.params.id;
        this.formulario.ativo = true;
    },
    created() {
        this.service = new NestedService('/risco-fonte-geradora');
        this.formulario.nome;
    },
    computed: {
        queryParams() {
            return {
                fonteGeradoraId: this.$route.params.id
            };
        }
    },
    methods: {
        async onLoadDataEdit(data) {
            this.formulario = data;
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave(response) {
            this.isInativo = true;
            this.submitted = false;
            this.formulario = response.data;
            this.$emit('habilitarAbas');
        },
        habilitarEdicao() {
            this.$emit('desabilitarAbas');
            this.isInativo = false;
        },
        cancelarEdicao(record) {
            this.isInativo = true;
            this.formulario = JSON.parse(JSON.stringify(record));
            this.$emit('habilitarAbas');
        }
    }
};
</script>
